<template>
    <div class="w3-theme-l1 w3-margin-top  w3-container w3-responsive" v-bind:style="'height:' + screenHeight + 'px;'">
        <div class="w3-row" style="padding-bottom:10px">
            <div class="w3-col l2">
                <ul class="w3-ul w3-hoverable w3-white" style="margin-right: 2px;">
                    <li class="w3-theme-d5" style="text-align: center; position: relative;">
                        Kelas
                        <button @click="showAddKelas" style="cursor: pointer;" title="Add Kelas" class="w3-display-right">
                            <i class="fa fa-plus"></i>
                        </button>
                    </li>
                    <li @click="parameters.kelas=item.sekolah_kelas_code;loadSiswa()" v-for="(item,index) in recKelass" :key="index" style="text-align: left;cursor: pointer; padding-left: 2px;padding-right: 2px;position: relative;">
                        <i class="fa" :class="parameters.kelas==item.sekolah_kelas_code ?'fa-check':''"></i>
                        {{item.sekolah_kelas_name}}
                        <span @click="showDeleteKelas(item)" class="w3-btn fa fa-trash-o w3-text-red w3-display-right" ></span>
                    </li>
                </ul>            
            </div>
            <div class="w3-col l2">
                <ul class="w3-ul w3-hoverable w3-white" style="margin-right: 2px;" >
                    <li class="w3-theme-d5" style="text-align: center; position: relative;">
                        Matapelajaran
                        <button @click="showAddMatapelajaran" style="cursor: pointer;" title="Add Matapelajaran" class="w3-display-right">
                            <i class="fa fa-plus"></i>
                        </button>
                    </li>
                    <li style="text-align: left;cursor: pointer; padding-left: 2px;padding-right: 2px;position: relative;" @click="loadTugas(item.matpel_code)" v-for="(item,index) in recMatapelajarans" :key="index">
                        <i class="fa" :class="parameters.matapelajaran==item.matpel_code ?'fa-check':''"></i>
                        {{item.matpel_name}}
                        <span @click="showDeletematapelajaran(item)" class="w3-btn fa fa-trash-o w3-text-red w3-display-right" ></span>
                    </li>
                </ul>            
            </div>
            <div class="w3-col l2">
                <ul class="w3-ul w3-hoverable w3-white" style="margin-right: 2px;" >
                    <li class="w3-theme-d5" style="text-align: center; position: relative;">
                        Tugas
                        <button @click="showAddTugas" style="cursor: pointer;" title="Add Tugas" class="w3-display-right">
                            <i class="fa fa-plus"></i>
                        </button>
                    </li>
                    <li style="text-align: left;cursor: pointer; padding-left: 2px;padding-right: 2px;position: relative;" v-for="(item,index) in recTugass" :key="index">
                        <div @dblclick="showEditTugas(item)" @click="parameters.tugas=item.guru_tugas_code;loadSiswa()"><i class="fa" :class="parameters.tugas==item.guru_tugas_code ?'fa-check':''"></i>
                        {{item.guru_tugas_name}}</div>
                        <span @click="showDeleteTugas(item)" class="w3-btn fa fa-trash-o w3-text-red w3-display-right" ></span>
                    </li>
                </ul>        
            </div>
            <div class="w3-rest">
                <ul class="w3-ul w3-hoverable w3-white" >
                    <li class="w3-theme-d5" style="text-align: center;position: relative;">
                        Siswa
                        <button @click="downloadTemplate" class="w3-red" type="button" style="position: absolute; left: 4px; top: 5px; cursor: pointer;" title="Download Template">
                            <i class="fa fa-download"></i>
                        </button>
                        <button @click="UploadFile" class="w3-blue" type="button" style="position: absolute; right: 1px; top: 5px;cursor: pointer;" title="Upload Nilai">
                            <i class="fa fa-upload"></i>
                        </button>
                    </li>
                </ul>
                <vue-scrolling-table v-bind:style="'height:'+  (screenHeight-50) +'px;'" :class="'w3-table w3-bordered w3-striped w3-white w3-small freezeFirstColumn freezeLastColumn'" :include-footer="true" :dead-area-color="'w3-theme'" :scroll-horizontal="true" id="grid">
                    <template slot="thead">
                        <tr class="w3-border-0 w3-border-right w3-border-bottom">
                            <th style="min-width:50px;" class="w3-light-grey w3-border-0 w3-border-right">
                                No
                            </th>
                            <th style="min-width:300px;cursor;">Nama</th>
                            <th style="min-width:100px;cursor;">Nilai</th>
                        </tr>
                    </template>
                    <template slot="tbody">
                        <div v-if="loading.siswa">
                            <DeLoading :show="loading.siswa"></DeLoading>
                        </div>
                        <tr v-else v-for="(dtl, index) in recNilais" style="cursor: pointer;" :class="(dtl.pilih==true?'bayangan w3-theme-l4':(index ==selectedRow ?'bayangan w3-theme-l4':'w3-opacity-min'))" :key="index" class=" w3-hover-border-grey">
                            <td style="min-width:50px;" class="w3-light-grey w3-border-0 w3-border-right">
                                <i :class="index == selectedRow ?'fa fa-caret-right':''"></i>{{index+1}}
                            </td>
                            <td v-on:click="selectedRow=index" style="min-width:300px"  class="w3-border-0 w3-border-left">
                                <span v-html="dtl.siswa_name"></span>
                            </td>
                            <td v-on:click="selectedRow=index" style="min-width:100px;padding:0px;"  class="w3-border-0 w3-border-left">
                                <money
                                    class="w3-input w3-small w3-border-0"
                                    style="width:100%;text-align:right;background: inherit;"
                                    type="text"
                                    @blur.native="saveData(dtl)"
                                    v-model="dtl.siswa_tugas_nilai_angka"
                                    v-bind="money"
                                />
                            </td>
                        </tr>
                    </template>
                </vue-scrolling-table>
            </div>
        </div>
        <!-- Kelas -->
        <div id="modalKelas" class="w3-modal">
            <div class="w3-modal-content w3-animate-zoom w3-card-4 w3-theme-d3 w3-round-large" style="width:600px;position: absolute;top: 50px;" id="modalBodyKelas">
                <header class="w3-container w3-theme-d3 w3-round-large" style="cursor:move;" id="modalBodyKelasheader" >
                    <span onclick="document.getElementById('modalKelas').style.display='none'"
                    class="w3-button w3-display-topright w3-round-large">&times;</span>
                    <h6><i class="fa fa-windows"></i> Add / Edit data</h6>
                </header>
                <div class="w3-container w3-padding w3-theme-l4">
                    <form method="post" id="formKelas" v-on:submit.prevent="saveDataKelas" action="#" autocomplete="off" >
                        <select v-model="recKelas.sekolah_kelas_code" class="w3-select w3-small w3-white w3-border w3-round">
                            <option v-for="(dtl, index) in $store.getters['modSekolahKelas/getRecsParam'](['sekolah_kelas_code','sekolah_kelas_name'])" :value="dtl.sekolah_kelas_code" :key="index">{{dtl.sekolah_kelas_name}}</option>
                        </select>
                    </form>
                </div>
                <footer class="w3-container w3-theme-l1">
                    <h6 class="w3-right">
                        <button form="formKelas" type="submit" class="w3-btn w3-small w3-theme-d5" style="margin-right:5px;"><i class="fa fa-save"></i> Save</button>
                        <button onclick="document.getElementById('modalKelas').style.display='none'" class="w3-btn w3-small w3-red"><i class="fa fa-close"></i> Close</button>
                    </h6>
                </footer>
            </div>
        </div>
        <!-- Matapelajaran -->
        <div id="modalMatapelajaran" class="w3-modal">
            <div class="w3-modal-content w3-animate-zoom w3-card-4 w3-theme-d3 w3-round-large" style="width:600px;position: absolute;top: 50px;" id="modalBodyMatapelajaran">
                <header class="w3-container w3-theme-d3 w3-round-large" style="cursor:move;" id="modalBodyMatapelajaranheader" >
                    <span onclick="document.getElementById('modalMatapelajaran').style.display='none'"
                    class="w3-button w3-display-topright w3-round-large">&times;</span>
                    <h6><i class="fa fa-windows"></i> Add / Edit data</h6>
                </header>
                <div class="w3-container w3-padding w3-theme-l4">
                    <form method="post" id="formMatapelajaran" v-on:submit.prevent="saveDataMatapelajaran" action="#" autocomplete="off" >
                        <select v-model="recMatapelajaran.matpel_code" class="w3-select w3-small w3-white w3-border w3-round">
                            <option v-for="(dtl, index) in $store.getters['modMatapelajaran/getRecsParam'](['matpel_code','matpel_name'])" :value="dtl.matpel_code" :key="index">{{dtl.matpel_name}}</option>
                        </select>
                    </form>
                </div>
                <footer class="w3-container w3-theme-l1">
                    <h6 class="w3-right">
                        <button form="formMatapelajaran" type="submit" class="w3-btn w3-small w3-theme-d5" style="margin-right:5px;"><i class="fa fa-save"></i> Save</button>
                        <button onclick="document.getElementById('modalMatapelajaran').style.display='none'" class="w3-btn w3-small w3-red"><i class="fa fa-close"></i> Close</button>
                    </h6>
                </footer>
            </div>
        </div>
        <!-- Tugas -->
        <div id="modalTugas" class="w3-modal">
            <div class="w3-modal-content w3-animate-zoom w3-card-4 w3-theme-d3 w3-round-large" style="width:600px;position: absolute;top: 50px;" id="modalBodyTugas">
                <header class="w3-container w3-theme-d3 w3-round-large" style="cursor:move;" id="modalBodyTugasheader" >
                    <span onclick="document.getElementById('modalTugas').style.display='none'"
                    class="w3-button w3-display-topright w3-round-large">&times;</span>
                    <h6><i class="fa fa-windows"></i> Add / Edit data</h6>
                </header>
                <div class="w3-container w3-padding w3-theme-l4">
                    <form method="post" id="formTugas" v-on:submit.prevent="saveDataTugas" action="#" autocomplete="off" >
                        <input placeholder="Nama Tugas" type="text" class="w3-input w3-border w3-round" v-model="recTugas.guru_tugas_name">
                    </form>
                </div>
                <footer class="w3-container w3-theme-l1">
                    <h6 class="w3-right">
                        <button form="formTugas" type="submit" class="w3-btn w3-small w3-theme-d5" style="margin-right:5px;"><i class="fa fa-save"></i> Save</button>
                        <button onclick="document.getElementById('modalTugas').style.display='none'" class="w3-btn w3-small w3-red"><i class="fa fa-close"></i> Close</button>
                    </h6>
                </footer>
            </div>
        </div>
    </div>
</template>
<script>
import XLSX from "xlsx";
export default {
    head: {
        title: {
            inner: "Penilaian"
        }
    },
    name: "Penilaian",
    components: {},
    data() {
        return {
            "screenHeight": 0,
            "recKelass": [],
            "recKelas": {},
            "recMatapelajarans": [],
            "recMatapelajaran": {},
            "recTugass": [],
            "recTugas": {},
            "recNilais": [],
            "parameters": {
                "kelas":"",
                "matapelajaran":"",
                "tugas":""
            },
            "selectedRow": -1,
            "loading": {
                "kelas": false,
                "matapelajaran": false,
                "tugas": false,
                "siswa": false
            },
            "money": {"decimal": ",", "thousands": ".", "prefix": "", "suffix": "", "precision": 2, "masked": false}
        };
    },
    methods : {
        setInit() {
            this.loadKelas();
            this.loadMatapelajaran();
        },
        loadKelas(){
            const self = this;
            self.apiPost("/brantas/gurukelas/search", {
                operator: "AND",
                kondisi: "=",
                field: "guru_kelas.guru_code",
                fieldvalue: self.getObjFromStoreage("user").guru_code,
                limit: 100,
                offset: 0,
                order: "sekolah_kelas_name"
            }, function(res){
                if(res.success){
                    self.recKelass = res.rec;
                }
            }, function(err){
                // eslint-disable-next-line no-console
                console.log(err);
            });
        },
        loadMatapelajaran(){
            const self = this;
            // self.parameters.kelas = kode;
            self.apiPost("/brantas/gurumatapelajaran/search", {
                operator: "AND",
                kondisi: "=;=",
                field: "guru_matapelajaran.guru_code;matapelajaran.matpel_jenis" ,
                fieldvalue: self.getObjFromStoreage("user").guru_code + ";I",
                limit: 100,
                offset: 0,
                order: "matpel_name"
            }, function(res){
                if(res.success){
                    self.recMatapelajarans = res.rec;
                }
            }, function(err){
                // eslint-disable-next-line no-console
                console.log(err);
            });
        },
        loadTugas(kode){
            const self = this;
            self.parameters.matapelajaran = kode;
            self.apiPost("/brantas/gurutugas/search", {
                "operator": "AND",
                "kondisi": "=;=;=",
                "field": "guru_tugas.guru_code;guru_tugas.tahun_ajaran_code;guru_tugas.matpel_code" ,
                "fieldvalue": self.getObjFromStoreage("user").guru_code + ";" + self.getObjFromStoreage("tahunajaran").tahun_ajaran_code + ";" + kode ,
                "limit": 100,
                "offset": 0,
                "order": "guru_tugas_name"
            }, function(res){
                if(res.success){
                    self.recTugass = res.rec;
                }
            }, function(err){
                // eslint-disable-next-line no-console
                console.log(err);
            });
        },
        loadSiswa(){
            const self = this;
            if (self.parameters.kelas=="" || self.parameters.kelas==undefined){
                // self.$toast.error("Pilih kelas dulu");
                return false;
            }
            if (self.parameters.tugas=="" || self.parameters.tugas==undefined){
                // self.$toast.error("Pilih tugas dulu");
                return false;
            }
            // Load siswa
            
            self.loading.siswa = true;

            self.apiPost("/brantas/siswa/search", {
                "operator" : "AND",
                "kondisi" : "=" ,
                "field": "siswa.sekolah_kelas_code" ,
                "fieldvalue": self.parameters.kelas ,
                "limit": 100,
                "offset": 0,
                "order": "siswa_name"
            }, async function(res){
                if(res.success){
                    let recs = [];
                    // ambil nilai
                    for (let index = 0; index < res.rec.length; index++) {
                        const element = res.rec[index];
                        // eslint-disable-next-line no-console
                        // console.log("Index " + index,element);
                        let resd = await self.apiAsync("/brantas/siswatugas/search",{
                            "operator" : "AND",
                            "kondisi" : "=;=" ,
                            "field": "siswa_tugas.siswa_code;siswa_tugas.guru_tugas_code" ,
                            "fieldvalue": element["siswa_code"] + ";" + self.parameters.tugas ,
                            "limit": "1",
                            "offset": "0",
                            "order": "siswa_tugas_nilai_angka desc"
                        });
                        if(resd["rec"].length>0){
                            recs.push({
                                "siswa_tugas_code": resd["rec"][0]["siswa_tugas_code"],
                                "guru_tugas_code": resd["rec"][0]["guru_tugas_code"],
                                "tahun_ajaran_code": resd["rec"][0]["tahun_ajaran_code"],
                                "matpel_code": resd["rec"][0]["matpel_code"],
                                "siswa_tugas_nilai_angka": resd["rec"][0]["siswa_tugas_nilai_angka"],
                                "siswa_tugas_nilai_huruf": resd["rec"][0]["siswa_tugas_nilai_huruf"],
                                "siswa_tugas_status": resd["rec"][0]["siswa_tugas_status"],
                                "siswa_code": element["siswa_code"],
                                "siswa_nis": element["siswa_nis"],
                                "siswa_name": element["siswa_name"],
                                "event": "edit",
                                "save": "T",
                                "pilih": false
                            });
                        }else{
                            recs.push({
                                "siswa_tugas_code": self.getCode(),
                                "guru_tugas_code": self.parameters.tugas,
                                "tahun_ajaran_code": self.getObjFromStoreage("tahunajaran").tahun_ajaran_code,
                                "matpel_code": self.parameters.matapelajaran,
                                "siswa_tugas_nilai_angka": 0,
                                "siswa_tugas_nilai_huruf": "A",
                                "siswa_tugas_status": "T",
                                "siswa_code": element["siswa_code"],
                                "siswa_nis": element["siswa_nis"],
                                "siswa_name": element["siswa_name"],
                                "event": "add",
                                "save": "T",
                                "pilih": false
                            });
                        }
                    }
                    self.recNilais = recs;
                    setTimeout(() => {
                        self.loading.siswa = false;
                    }, 1000);
                    
                }
            }, function(err){
                // eslint-disable-next-line no-console
                console.log(err);
            });
        },
        saveData(dtl){
            const self = this;
            // eslint-disable-next-line no-console
            console.log(dtl);
            self.apiPost("/brantas/siswatugas/" + dtl.event, dtl, function(res){
                if(res.success){
                    dtl.event = "edit";
                    self.$toast.success("Data sudah tersimpan");
                }
            }, function(err){
                // eslint-disable-next-line no-console
                console.log(err);
                self.$toast.error(err);
            });
        },
        // Add kelas
        showAddKelas(){
            this.recKelas = {
                "guru_kelas_code": this.getCode(),
                "guru_code": this.getObjFromStoreage("user").guru_code,
                "sekolah_kelas_code": "",
                "guru_kelas_status": "T"
            };
            document.getElementById("modalKelas").style.display = "block";
        },
        saveDataKelas(event){
            const self = this;
            self.apiPost("/brantas/gurukelas/add", self.recKelas, function(res){
                if(res.success){
                    self.$toast.success("Sukses add kelas");                    
                    document.getElementById("modalKelas").style.display = "none";
                    self.loadKelas();
                }
            }, function(err){
                self.$toast.error(err);
            });
            event.preventDefault();
            return false;
        },
        showDeleteKelas (dt) {
            const self = this;
            // eslint-disable-next-line no-undef
            swal(
                {
                    title: "Yakin Ingin Menghapus Data ..!",
                    text: "Data Yang sudah di hapus tidak akan bisa dikembalikan",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Ya, Hapus Data",
                    cancelButtonText: "Tidak, Batal Hapus",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        let vparams = { "guru_kelas_code" : dt.guru_kelas_code };
                        self.apiPost("/brantas/gurukelas/delete", vparams, function (response) {
                            if (response.success) {
                                self.loadKelas();
                                self.$toast.success("Hapus sukses", "Delete");
                            } else {
                                self.$toast.error("Gagal Hapus " + response.msg, "Delete");
                            }
                        });
                    }
                }
            );
        },
        // Add Matapelajaran
        showAddMatapelajaran(){
            this.recMatapelajaran = {
                "guru_matpel_code": this.getCode(),
                "guru_code": this.getObjFromStoreage("user").guru_code,
                "matpel_code": "",
                "guru_matpel_status": "T"
            };
            document.getElementById("modalMatapelajaran").style.display = "block";
        },
        saveDataMatapelajaran(event){
            const self = this;
            self.apiPost("/brantas/gurumatapelajaran/add", self.recMatapelajaran, function(res){
                if(res.success){
                    self.$toast.success("Sukses add kelas");                    
                    document.getElementById("modalMatapelajaran").style.display = "none";
                    self.loadMatapelajaran();
                    self.loadTugas(self.recMatapelajaran.matpel_code);
                }
            }, function(err){
                self.$toast.error(err);
            });
            event.preventDefault();
            return false;
        },
        showDeletematapelajaran (dt) {
            const self = this;
            // eslint-disable-next-line no-undef
            swal(
                {
                    title: "Yakin Ingin Menghapus Data ..!",
                    text: "Data Yang sudah di hapus tidak akan bisa dikembalikan",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Ya, Hapus Data",
                    cancelButtonText: "Tidak, Batal Hapus",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        let vparams = { "guru_matpel_code" : dt.guru_matpel_code };
                        self.apiPost("/brantas/gurumatapelajaran/delete", vparams, function (response) {
                            if (response.success) {
                                self.loadMatapelajaran();
                                self.$toast.success("Hapus sukses", "Delete");
                            } else {
                                self.$toast.error("Gagal Hapus " + response.msg, "Delete");
                            }
                        });
                    }
                }
            );
        },
        // Untuk Menambahkan Tugas
        showAddTugas(){
            if(this.parameters.kelas == "" || this.parameters.kelas == undefined){
                this.$toast.error("Pilih Kelas");
                return false;
            }
            if(this.parameters.matapelajaran == "" || this.parameters.matapelajaran == undefined){
                this.$toast.error("Pilih Matapelakaran");
                return false;
            }
            this.recTugas = {
                "guru_tugas_code": this.getCode(),
                "guru_code": this.getObjFromStoreage("user").guru_code,
                "tahun_ajaran_code": this.getObjFromStoreage("tahunajaran").tahun_ajaran_code,
                "matpel_code": this.parameters.matapelajaran,
                "guru_tugas_name": "",
                "sekolah_kelas_code": this.parameters.kelas,
                "guru_tugas_status": "T",
                "event": "add"
            };
            document.getElementById("modalTugas").style.display = "block";
        },
        showEditTugas(dtl){
            dtl["event"] = "edit";
            this.recTugas = Object.assign({}, dtl);
            document.getElementById("modalTugas").style.display = "block";
        },
        saveDataTugas(event){
            const self = this;
            self.apiPost("/brantas/gurutugas/" + self.recTugas.event, self.recTugas, function(res){
                if(res.success){
                    self.$toast.success("Sukses add kelas");                    
                    document.getElementById("modalTugas").style.display = "none";
                    self.loadTugas(self.parameters.matapelajaran);
                }
            }, function(err){
                self.$toast.error(err);
            });
            event.preventDefault();
            return false;
        },
        showDeleteTugas (dt) {
            const self = this;
            // eslint-disable-next-line no-undef
            swal(
                {
                    title: "Yakin Ingin Menghapus Data ..!",
                    text: "Data Yang sudah di hapus tidak akan bisa dikembalikan",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Ya, Hapus Data",
                    cancelButtonText: "Tidak, Batal Hapus",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        let vparams = { "guru_tugas_code" : dt.guru_tugas_code };
                        self.apiPost("/brantas/gurutugas/delete", vparams, function (response) {
                            if (response.success) {
                                self.loadTugas(self.parameters.matapelajaran);
                                self.$toast.success("Hapus sukses", "Delete");
                            } else {
                                self.$toast.error("Gagal Hapus " + response.msg, "Delete");
                            }
                        });
                    }
                }
            );
        },
        downloadTemplate(){
            const self = this;
            
            if (self.recNilais.length<=0){
                self.$toast.error("Siswa kosong");
                return false;
            }
            self.showLoading("Generate excel");
            let wb = XLSX.utils.book_new();
            wb.Props = {
                Title: "Export Nilai Siswa",
                Subject: "Export Nilai Siswa",
                Author: "mbahsomo@gmail.com",
                CreatedDate: new Date()
            };
            wb.SheetNames.push("Nilai Siswa");
            let wsdata = [
                ["EVENT","ID","CODE","NIS","Nama","Nilai"]
            ];
            for (let index = 0; index < self.recNilais.length; index++) {
                const element = self.recNilais[index];
                wsdata.push([
                    element["event"], 
                    element["siswa_tugas_code"], 
                    element["siswa_code"], 
                    element["siswa_nis"], 
                    element["siswa_name"], 
                    element["siswa_tugas_nilai_angka"]
                ]);
            }
            let ws = XLSX.utils.aoa_to_sheet(wsdata);
            ws["!cols"] = [{ width: 5 }, { width: 5 }, { width: 10 }, { width: 10 }, { width: 30 }, { width: 10, fill: { fgColor: { rgb: "5ebd3e" } } }];
            wb.Sheets["Nilai Siswa"] = ws;
            XLSX.write(wb, {bookType:"xlsx", type: "binary"});
            self.hideLoading();
            XLSX.writeFile(wb, "template-nilai_siswa.xlsx");
        },
        selectFile(contentType, multiple) {
            return new Promise(resolve => {
                let input = document.createElement("input");
                input.type = "file";
                input.multiple = multiple;
                input.accept = contentType;
                // eslint-disable-next-line no-unused-vars
                input.onchange = _ => {
                    let files = Array.from(input.files);
                    if (multiple) {
                        resolve(files);
                    } else {
                        resolve(files.first);
                    }
                };
                input.click();
            });
        },
        async UploadFile(){
            let self = this;
            if(this.parameters.kelas == "" || this.parameters.kelas == undefined){
                this.$toast.error("Pilih Kelas");
                return false;
            }
            if(this.parameters.matapelajaran == "" || this.parameters.matapelajaran == undefined){
                this.$toast.error("Pilih Matapelakaran");
                return false;
            }

            if (self.recNilais.length<=0){
                self.$toast.error("Siswa kosong");
                return false;
            }

            let files = await self.selectFile("xls", true);
            for (let i = 0, f = files[i]; i != files.length; ++i) {
                let reader = new FileReader();
                reader.onload = function(e) {
                    let data ;
                    if (!e) {
                        data = reader.content;
                    } else {
                        data = e.target.result;
                    }
                    try {
                        let workbook = XLSX.read(data, { type: "binary" });
                        self.readFile(workbook);
                    } catch (e) {
                        self.readFile(e);
                    }

                };

                if (!FileReader.prototype.readAsBinaryString) {
                    FileReader.prototype.readAsBinaryString = function(fileData) {
                        let binary = "";
                        let pt = this;
                        let reader = new FileReader();
                        reader.onload = function(e) {
                            let bytes = new Uint8Array(reader.result);
                            let length = bytes.byteLength;
                            for (let i = 0; i < length; i++) {
                                binary += String.fromCharCode(bytes[i]);
                            }
                            pt.content = binary;
                            pt.addEventListener("load", e, false);
                        };
                        reader.readAsArrayBuffer(fileData);
                    };
                }

                reader.readAsBinaryString(f);
            }
        },
        
        readFile(workbook){
            let self = this;
            let recs = [];
            for (let sheetName in workbook.Sheets) {
                if(workbook.Sheets[sheetName]["!ref"]!=undefined){
                    recs = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {header:1,dateNF:"YYYY-MM-DD;@",raw: false});
                }
            }
            for (let index = 0; index < recs.length; index++) {
                const element = recs[index];
                if (index>0){
                    self.selectedRow = index-1;
                    // eslint-disable-next-line no-console
                    console.log(element);
                    let obj = self.findObj(self.recNilais,"siswa_code",element[2]);
                    // eslint-disable-next-line no-console
                    console.log(obj);
                    if (parseFloat(obj["siswa_tugas_nilai_angka"])!=parseFloat(element[5])){
                        obj["siswa_tugas_nilai_angka"] = parseFloat(element[5]);
                        self.saveData(obj);
                    }
                }
            }
        }
    },
    computed :{
        
    },
    filters: {
    },
    watch: {
        "$route": function(){
        }
    },
    created (){
        this.$store.getters["modSekolahKelas/pages"]["batas"] = 100;
        this.$store.dispatch("modSekolahKelas/loadData");
        this.$store.getters["modMatapelajaran/pages"]["batas"] = 100;
        this.$store.dispatch("modMatapelajaran/loadData");
    },
    mounted(){
        let self = this;
        // console.log("User",self.getObjFromStoreage("user").guru_code);
        self.screenHeight = window.innerHeight-100;
        self.setInit();
        this.dragElement(document.getElementById("modalBodyKelas"));
        this.dragElement(document.getElementById("modalBodyMatapelajaran"));
        this.dragElement(document.getElementById("modalBodyTugas"));
    }
};
</script>